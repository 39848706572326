import React from "react"
import { Accordion, AccordionContext, Card, useAccordionButton } from "react-bootstrap"
import parse from 'html-react-parser'
import Fade from "react-reveal/Fade"
import CloseBlack from "../../assets/images/close-black.svg"

function FAQ(props) {
  const CustomToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = React.useContext(AccordionContext)
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    )
    const isCurrentEventKey = currentEventKey.activeEventKey === eventKey
    if (isCurrentEventKey) {
      // alert(currentEventKey)
    }

    return (
      <button
        type="button"
        className={isCurrentEventKey ? "active" : ""}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    )
  }

  return (
    <>
      <Accordion defaultActiveKey={1}>
        {props.question.map((q, i) => {
          return (
            <Fade delay={(i + 1) * 130} duration="500">
              <CustomToggle eventKey={i+1} >
                {q}
                <img id={i} src={CloseBlack} className="CloseBlack" alt="close" />
                <Accordion.Collapse eventKey={i+1}>
                  <Card.Body>{parse(props.answer[i].html)}</Card.Body>
                </Accordion.Collapse>
              </CustomToggle>
            </Fade>
          )
        })}
      </Accordion>
    </>
  )
}

export default FAQ
