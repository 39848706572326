import React, { useState, useEffect } from "react"
import Buttons from "../common/button"
import { checkValidEmail } from "./../utility/validation"

let IntegrationReq = props => {
  const [toolName, setToolName] = useState("")
  const [email, setEmail] = useState("")

  const [toolNameError, setToolNameError] = useState("")
  const [toolNameErrorPop, setToolNameErrorPop] = useState("none")
  const [emailError, setEmailError] = useState("")
  const [emailErrorPop, setEmailErrorPop] = useState("none")

  const [submitPress, setSubmitPress] = useState(false)

  useEffect(() => {
    if (submitPress) {
      if (toolName === "") {
        setToolNameError("1px solid #ff0055")
        setToolNameErrorPop("block")
      } else {
        setToolNameError("1px solid #190429")
        setToolNameErrorPop("none")
      }

      if (email === "" || !checkValidEmail(email)) {
        setEmailError("1px solid #ff0055")
        setEmailErrorPop("block")
      } else {
        setEmailError("1px solid #190429")
        setEmailErrorPop("none")
      }
    }
  })

  return (
    <form>
      {/* <div className="grid-container">
        <div className="grid-lg-2 grid-md-1 grid-sm-1"> */}
      <div className="last-name position-relative">
        <input
          type="text"
          name="TOOLNAME"
          value={toolName}
          onChange={e => setToolName(e.target.value)}
          style={{ border: toolNameError }}
          className={`${toolName.length > 0 && "valid"}`}
        />
        <span className="floating-label p14 position-absolute">
          {" "}
          <p
            style={{
              color: toolNameErrorPop === "block" && "#FF0055",
            }}
          >
            Tool name
          </p>
        </span>
        <div
          className="error-pop position-absolute p13"
          style={{ display: toolNameErrorPop }}
        >
          <p>Please enter a tool name for integration.</p>
        </div>
      </div>
      {/* </div>
        <div className="grid-lg-2 grid-md-1 grid-sm-1"> */}
      <div className="position-relative">
        <input
          type="text"
          name="EMAIL"
          value={email}
          onChange={e => setEmail(e.target.value)}
          style={{ border: emailError }}
          className={`${email.length > 0 && "valid"}`}
        />
        <span className="floating-label p14 position-absolute">
          {" "}
          <p
            style={{
              color: emailErrorPop === "block" && "#FF0055",
            }}
          >
            Email address
          </p>
        </span>
        <div
          className="error-pop position-absolute p13"
          style={{ display: emailErrorPop }}
        >
          <p>Please enter a vaid email.</p>
        </div>
      </div>
      {/* </div>
      </div> */}

      <Buttons type="submit" theme="primary" text="SUBMIT REQUEST" />
    </form>
  )
}

export default IntegrationReq
