import { Link } from "gatsby"
import React from "react"
import CarouselComponent from "./carousel-component"

let Carousal = props => {
  const responsive = {
    desktopLarge: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  }

  return (
    <CarouselComponent responsive={responsive} swipeable status>
      {props.images.map((image, i) => {
        return (
          <Link to={`/blog/${props.links[i]}/`} className="no-deco">
            <div className="seo-image-caros">
              <img src={image.url} alt="img" />
            </div>
          </Link>
        )
      })}
    </CarouselComponent>
  )
}

export default Carousal
