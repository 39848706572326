import React, { useState } from "react"
import { Container} from "react-bootstrap"
import "react-phone-input-2/lib/style.css"
import ErrorIcon from "../../assets/images/signup-error.svg"
import IntegrationReq from './integration-request-form'

function PopupIntegrationReq(props) {

  const [displayError, setDisplayError] = useState(false)
  const [errorText, setErrorText] = useState("")

  return (
    <div
      className="popup-integration-request"
      style={{ display: !props.visibility && "none" }}
    >
      <div
        className="wrap position-fixed"
        onClick={props.togglePopup}
        onKeyDown={props.togglePopup}
        role="button"
        tabIndex={0}
      >
        {/*  */}
      </div>

      <Container className="position-fixed">
        <div className="overflow">
          <div
            className="close position-absolute"
            onClick={props.togglePopup}
            onKeyDown={props.togglePopup}
            role="button"
            tabIndex={0}
          >
            <img
              src="https://media.graphcms.com/zpyoXoSXRuWnSQ1KauLF"
              alt="close"
              height="24"
              width="24"
            />
          </div>

          <div className="forms">
            <span
              className="info"
              style={{
                display: !displayError ? "block" : "none",
              }}
            >
                <h4> {props.title} </h4>
            </span>
            <div
              className="api-error p14"
              style={{
                display: displayError ? "block" : "none",
              }}
            >
              <img
                src={ErrorIcon}
                height="10"
                width="10"
                className="position-relative"
                alt="img"
              />
              <p>{errorText}</p>
            </div>
            <IntegrationReq />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PopupIntegrationReq
