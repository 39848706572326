import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import EsArrowDown from "../assets/images/resources/es-arrow-down.png"
import ImageCarousal from "../components/common/image-caraousal"
import FAQ from "../components/seo/faq"
import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/component/sidebar-small.scss"
import "../styles/pages/seo-post.scss"

function IntegrationsContent(props) {
  const { seo } = props
  let contentArray = parse(seo.content.html)
  let wrapCount = []
  let wrapContent = []
  let tagCount = 0

  contentArray.map((ht, i) => {
    if (ht.props.className === "pink-tag") {
      wrapCount.push({
        index: i,
        type: "text",
        id: `tag${tagCount}`,
      })
      tagCount++
    }
  })

  for (var i = 0; i < wrapCount.length; i++) {
    if (i === wrapCount.length - 1) {
      wrapContent.push({
        content: contentArray.slice(wrapCount[i].index),
        type: wrapCount[i].type,
      })
    } else {
      wrapContent.push({
        content: contentArray.slice(wrapCount[i].index, wrapCount[i + 1].index),
        type: wrapCount[i].type,
      })
    }
  }

  const addClasses = buttons => {
    buttons.forEach(btn => {
      btn.classList.add("btn", "btn-primary", "white", "arrow")
    })
  }

  const ScrollToTag = i => {
    document
      .getElementById(`tag${i}`)
      .scrollIntoView({ behavior: "smooth", block: "center" })
  }

  useEffect(() => {
    let allTags = document.querySelectorAll("#seoContent > .pink-tag")

    let ctaButtons = document.querySelectorAll(".rich-cta td:last-child p a")
    let ebookButtons = document.querySelectorAll(".ebook-cta td:last-child p a")

    addClasses(ctaButtons)
    addClasses(ebookButtons)

    allTags.forEach((tag, index) => {
      tag.id = "tag" + index
      if (index) {
        tag.classList.add("mt80")
      }
    })
  }, [])

  const [currentNavValue, setCurrentNavValue] = useState("")
  const [tagId, setTagId] = useState(0)

  useEffect(() => {
    setCurrentNavValue(
      contentArray[wrapCount.find(wrap => wrap.type === "text").index].props
        .children
    )
  }, [])

  return (
    <div className={`${props.className}`}>
      <div className="seo-content">
        <center>
          <Container className="seo-container">
            <section
              className="es-nav-wrap fe-gl-nav-mob d-mob"
              id="navContent"
            >
              <Container>
                <center>
                  <div className="outer">
                    <div className="select">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="drp-btn"
                          className="drp-toggle"
                        >
                          {currentNavValue}
                          <img
                            src={EsArrowDown}
                            height="1"
                            width="1"
                            alt="img"
                            className="arrow-down"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu id="drp-menu">
                          {wrapCount.map((t, i) => {
                            return (
                              t.type === "text" && (
                                <Dropdown.Item
                                  onClick={() => {
                                    setCurrentNavValue(
                                      contentArray[t.index].props.children
                                    )
                                    ScrollToTag(i)
                                  }}
                                >
                                  <p className="m-0">
                                    {contentArray[t.index].props.children}
                                  </p>
                                </Dropdown.Item>
                              )
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </center>
              </Container>
            </section>
            <Row>
              <Col lg={4}>
                <div className="sticky-top seo-opt d-dsk">
                  <div className="seo-options-block">
                    <p className="title font-roboto fw-600 text-deep-purple mb30">QUICK ACCESS</p>
                    {wrapCount.map((t, i) => {
                      if (t.type === "text") {
                        return (
                          <div className="options">
                            <div className="position-relative value p14">
                              <p
                                onClick={() => {
                                  ScrollToTag(i)
                                  setCurrentNavValue(
                                    contentArray[t.index].props.children
                                  )
                                  setTagId(i)
                                }}
                                className={`${
                                  tagId === i
                                    ? "text-deep-purple"
                                    : ""
                                }`}
                              >
                                {contentArray[t.index].props.children}{" "}
                              </p>
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                  {seo.isCarouselAvailable === "Yes" && (
                    <div className="caraousel-widget">
                      <ImageCarousal
                        images={seo.carsouelImages}
                        links={seo.carouselLinks}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={8}>
                <div className="rich-text secondary-font" id="seoContent">
                  {parse(seo.content.html)}
                </div>
              </Col>
            </Row>
          </Container>
        </center>
      </div>

      {seo.faqQuestions.length ? (
        <div className="faq">
          <Container>
            <Row>
              <Col lg={3}>
                <h2 className="font-roboto">Frequently asked questions</h2>
                <span>
                  <p>We have them answered.</p>
                </span>
              </Col>
              <Col>
                <div className="section">
                  <FAQ question={seo.faqQuestions} answer={seo.faqAnswers} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}
    </div>
  )
}

export default IntegrationsContent